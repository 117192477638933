import { EnvType, IEnv } from './type';

export const environment: IEnv = {
  type: EnvType.dev,
  socketUrl: 'https://dev.wearewarp.com',
  backendUrl: 'https://dev.wearewarp.com',
  authUrl: 'https://auth.dev.wearewarp.com',
  customerWebUrl: 'https://customer-web.dev.wearewarp.com',
  carrierWebUrl: 'https://carrier-web.dev.wearewarp.com',
  warehouseWebUrl: 'https://warehouse-web.dev.wearewarp.com',
  trackingWebUrl: 'https://tracking-web.dev.wearewarp.com',
  routingUrl: 'https://warp-dev.cachua.xyz/japi/dev',
  ratingUrl: 'https://warp-dev.cachua.xyz/japi',
  routingAppUrl: 'https://warp-dev.cachua.xyz/routing',
  zipcodeUrl: 'https://gw.wearewarp.com/us-cities/v1/zipcode',
  twofaRequired: false,
  eventUrl: 'https://dev-event.wearewarp.link',
  routing2Url: 'https://warp-socket.cachua.xyz/api-routing-service',
  planningUrl: 'https://warp-socket.cachua.xyz/api-planning',
  dataorchUrl: '',
  supportUrl: '',
  wsUrl: 'wss://gw.wearewarp.com/ws',
  restWarehouseBackendUrl: 'https://warehouse.dev.wearewarp.com',
  commlogServiceUrl: "https://commlog.dev.wearewarp.com",
  mapboxgl: {
    useProxy: true,
    apiUrl: 'https://mapbox.dev.wearewarp.com',
    eventUrl: 'https://mapbox.dev.wearewarp.com/events/v2',
  },
  workQueueServiceUrl: 'https://works.dev.wearewarp.com',
  replaySession: {
    projectKey: '',
    ingestPoint: 'https://internal-tracker.wearewarp.com/ingest',
    assetUrl: '',
  },
  carrierRatingServiceUrl: 'https://rating.dev.wearewarp.com'
};
