import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpResponse,
} from '@angular/common/http';
import OpenReplay from '@openreplay/tracker'
import trackerAssist from '@openreplay/tracker-assist';
import { environment } from '@env/environment';
import { EnvType } from '@env/type';
import { AppConst } from '@app/const.generated';
import { AuthUser } from '@wearewarp/types/common/auth';
import { ResponseAdminOpsRole } from '@wearewarp/types/rest-api/admin/user';
import { RoleManager } from './role-manager';
import { Utils } from './utils';
import { AdminAuthUser } from '@wearewarp/types/rest-api/admin';


type ReqRespType = {
  request: HttpRequest<any>,
  response: HttpResponse<any>
}

@Injectable({
  providedIn: 'root'
})
export class ReplaySessionService {
  tracker: OpenReplay | null = null

  constructor() {
    // if(environment.type !== EnvType.prod) return;
    if (!environment.replaySession?.projectKey?.length) return;

    this.tracker = new OpenReplay({
      projectKey: environment.replaySession.projectKey,
      ingestPoint: environment.replaySession.ingestPoint,
      __DISABLE_SECURE_MODE: ![EnvType.prod, EnvType.stg].includes(environment.type),
      defaultInputMode: 0,
      obscureInputDates: false,
      obscureTextEmails: false,
      obscureInputEmails: false,
      obscureInputNumbers: false,
      network: {
        sessionTokenHeader: false,
        failuresOnly: false,
        ignoreHeaders: false,
        capturePayload: false,
        captureInIframes: true,
        sanitizer: (data) => {
          if (!data.url.includes('api')) return null;
          if (!data.request.headers['Content-Type']?.includes('application/json')) return null;
          if (data.response.body && data.response.body.length > 200 * 1024) {
            data.response.body = {
              note: 'Response body is hidden for network traffic optimization',
              bodyLength: data.response.body.length
            } // Hide response body. heavy network traffic
          }
          return data;
        }
      }
    });

    this.tracker.use(trackerAssist({
      onAgentConnect: () => {
        console.log("Live session started")
        const onAgentDisconnect = () => console.log("Live session stopped")
        return onAgentDisconnect
      },
      onCallStart: () => {
        console.log("Call started")
        const onCallEnd = () => console.log("Call ended")
        return onCallEnd
      },
      config: {
        'iceServers': [{ 'urls': 'stun:stun.l.google.com:19302' }]
      }
    }));
    this.tracker.start({
      metadata: {
        AppVersion: AppConst.Version,
      }
    });
  }

  sendEventToReplaySession(event: string, params: ReqRespType): void {
    this.tracker?.event(event, params)
  }

  setUser(user: AdminAuthUser): void {
    if(!user) return;
    this.tracker?.setMetadata('UserName', this.getFullName(user));
    this.tracker?.setMetadata('UserRoles', user?.roles?.map(role => this.getRoleName(role)).join(', '));
    this.tracker?.setMetadata('WarpID', String(user?.warpId));
    this.tracker?.setUserID(user.email);
  }
  setMetadata(key: string, value: any): void {
    this.tracker?.setMetadata(key, value);
  }

  getFullName(user) {
    if (!user) return '';
    if (user.fullName) return user.fullName;
    let text = user.firstName || '';
    if (user.lastName) {
      if (text) {
        text += ' ';
      }
      text += user.lastName;
    }
    return text;
  }

  getRoleName(role): string {
    if (role.name) return role.name;
    if (role.code == RoleManager.client) return 'Customer';
    if (role.code == RoleManager.sale) return 'Sales';
    if (role.code == RoleManager.warehouseOperator) return 'WarehouseOperator';
    return Utils.capitalizeFirstLetter(role.code);
  }
}